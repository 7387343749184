
import { defineComponent, PropType } from "vue";
import { IUpdateCarrierDto } from "@/api/services/packages/esim/carriers/types";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppSwitch from "@/components/Form/AppSwitch.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";

interface IUpdateCarrierModalData extends IUpdateCarrierDto {
  valid: boolean;
  netGensChanges: { id: number; active: boolean }[];
  extraRate: number;
}
export default defineComponent({
  components: {
    AppCheckbox,
    AppButton,
    AppSwitch,
    AppInputsValidationProvider,
    Dialog,
    AvatarUpload,
    AppInput
  },

  props: {
    editData: { type: Object as PropType<IUpdateCarrierDto>, required: true },
    opened: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  emits: {
    hide: null,
    submit: null
  },

  data() {
    return {
      title: "",
      rates: [],
      image: "",
      netGens: [],
      netGensChanges: [],
      valid: false,
      active: false,
      extraRate: 0
    } as IUpdateCarrierModalData;
  },

  computed: {
    modalTitle() {
      return this.isEditing ? this.editData.title : "New Carrier";
    },

    buttonLabel() {
      return this.isEditing ? "Save" : "Add Carrier";
    }
  },

  watch: {
    editData: {
      immediate: true,
      handler({ title, netGens, image, active }: IUpdateCarrierDto) {
        this.title = title;
        this.image = image;
        this.netGens =
          netGens && netGens?.map(gen => JSON.parse(JSON.stringify(gen)));
        this.netGensChanges = [];
        this.active = active;
      }
    },

    netGensChanges: {
      deep: true,
      handler() {
        this.netGensChanges.forEach(gen => {
          this.netGens.forEach(_gen => {
            if (_gen.networkGeneration.id === gen.id) {
              _gen.active = gen.active;
            }
          });
        });
      }
    }
  },

  methods: {
    fileUpload(file: File) {
      this.image = file;
    },

    changeErrorState(error: boolean) {
      this.valid = !error;
    },

    changeNetGens(id: number, active: boolean) {
      const exist = this.netGensChanges.find(generation => {
        return generation.id === id;
      });

      if (exist) {
        exist.active = active;
        return;
      }

      this.netGensChanges.push({ id, active });
    },

    submitHandler() {
      const { active, title, image, netGens, netGensChanges } = this;
      const data = {
        active,
        title,
        image,
        netGens,
        netGensChanges
      };

      this.$emit("submit", data);
    }
  }
});
